@font-face {
  font-family: 'NoirPro';
  src: local('NoirPro-Regular'),
    url('./assets/fonts/NoirPro/NoirPro-Regular.woff2') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'NoirPro-Medium';
  src: local('NoirPro-Medium'),
    url('./assets/fonts/NoirPro/NoirPro-Medium.woff2') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Open Sans';
  src: local('OpenSans-Regular'),
    url('./assets/fonts/OpenSans/OpenSans-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Open Sans Medium';
  src: local('OpenSans-Medium'),
    url('./assets/fonts/OpenSans/OpenSans-Medium.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Blinker';
  src: local('Blinker'),
    url('./assets/fonts/Blinker/Blinker-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Blinker-Bold';
  src: local('Blinker-Bold'),
    url('./assets/fonts/Blinker/Blinker-Bold.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'GochiHand-Regular';
  src: local('GochiHand-Regular'),
    url('./assets/fonts/GochiHand/GochiHand-Regular.ttf') format('truetype');
  font-weight: 400;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}

@keyframes slide-in {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0%);
    opacity: 1;
  }
}
